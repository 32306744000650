@import "../../scss/variables";
@import "../../scss/mixin";

.Footer {
	display: block;
	align-items: left;
	justify-content: left;
	text-align: left;
	margin: 3.5vh 0 2.5vh;
	color: #969696;
	padding-top: 1em;
	border-top:1px solid #333333;
	

	@include mq('sm'){
		font-size: 14px;
	}
	@include mq('md'){
		font-size: 15px;
	}
	@include mq(1920px){
		font-size: 16px;
	}

	&__pagewrp {
	  	display: block;
	  	align-items: center;
		margin: 0 4.5vw;
		margin-bottom: 10px;	
		transition: color 0.4s cubic-bezier(0.6, -0.05, 0.01, 0.99);

		&__block {
			text-align: center;
			display: inline-block;
			width: 100%;
			margin:5px 0px;

			@include mq('sm'){
				width: 50%;
				text-align: left;
				margin: 10px 0px;
			}

			&:first-child {
				margin-top: -30px;
			}

			&:last-child {
				text-align: center; 
				h4 {
					margin-right: 3.5vw;
    				margin-bottom: 10px;
					display:none;

					@include mq('sm'){
						display:block;
					}
					
				}

				.Footer__pagewrp__block--links {
					margin: 0 0.5vw 0 0.5vw;
				}

				.Footer__pagewrp__block--links:after {
					display: none;
				}

				@include mq('sm'){
					text-align: right; 
				}
			}

			

			&--links {
				display: inline-block;
				width: auto;
				color: #ccc;
				text-decoration: none;
				font-size: 2vw;
				margin: 0 0.3vw 0 0;

				@include mq('sm'){
					font-size: 13px;
				}
				@include mq('md'){
					font-size: 14px;
				}
				@include mq('lg'){
					font-size: 14px;
				}

				&:after {
					content: "|";
					display: inline-block;
					margin: 0 1vw 0 1vw;
					font-size: 2vw;
					text-shadow: 0 1px 1px rgba(0, 0, 0, 0);
					color: rgba($white, 0.8);

					@include mq('sm'){
						font-size: 12px;
					}
				}

				&:last-child:after {
					display: none;
				}
				&--disabled {
					pointer-events: none;
					color: #ccc;
					text-decoration: none;
					margin-right: 10px;
				}
				
			}
		}

		
		
	}

	&__linkwrp {
	  display: block;
	  text-align: center;
	  font-weight: bold;
	  margin: 0 4.5vw;
	  margin-bottom: 10px;	
	  transition: color 0.4s cubic-bezier(0.6, -0.05, 0.01, 0.99);
	  font-size: 2vw;

	  @include mq('sm'){
		  font-size: 11px;
		  text-align: left;
	  }
	  @include mq('md'){
		  font-size: 12px;
	  }
	  @include mq('lg'){
		  font-size: 13px;
	  }

	  &--link {
		  color: inherit;
		  text-decoration: none;

		  &:hover {
			  color: $white;
		  }
	  }
	  
  }

	&__developer {
		display: block;
		align-items: center;
		color: inherit;
		font-weight: 400;
		margin-left: 5px;
		transition: color 0.4s cubic-bezier(0.6, -0.05, 0.01, 0.99);
		font-size: 1.7vw;

		@include mq('sm'){
			font-size: 10px;
		}
		@include mq('md'){
			font-size: 11px;
		}
		@include mq('lg'){
			font-size: 12px;
		}

		&--link {
			color: inherit;
			text-decoration: none;

			&:hover {
				color: $white;
			}

			&--disabled {
				cursor: none;
			}
		}
		&--disabled {
			opacity: 0;
		}
  	}

}
