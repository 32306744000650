@import '../../scss/mixin';
@import '../../scss/variables';

.Player {
    top:70px;
    overflow: hidden;
    
    &__info{
        position: absolute;
        background: rgb(0,0,0);
        background: linear-gradient(103deg, rgba(0,0,0,0.8408613445378151) 40%, rgba(255,255,255,0) 100%);
        height: 100%;
        width: 100%;


      &--clickable {        

        &:hover {
          opacity: 1;

          .CHPlayer__info--text {
            color: $yellow;
            transition: 2s;
          }

          .CHPlayer__info--text:before {
            border-bottom: 3px solid $yellow;
            transition: 1s;
          }
        }
      }

      &--extras {
            padding: 0.6em 1em 0.3em;   

            &-label {
              font-weight:600;
              font-size:2vh;
              color: #777;
            }
            &-description {
              display:block;
              font-size:2vh;
              margin-left:10px;
              color: #ddd;
            }
      }

      &--social {
        padding: 1.6em 1em 0.3em;    

        &-label {
          font-size:2vh;
          font-weight:600;
          color: #777;
        }
  }
        
    }

    &__overlay {

      &--button {
        margin-top: 5px;
        margin-left: -5px;
        cursor: pointer;
        opacity: 0.8;
        z-index: 999;

        &:hover {
            opacity: 1;
        }
      }
      &--text {
        &:after {
            content: "";
            border-bottom: 2px solid $yellow;
            width: 60px;
            position: absolute;
            bottom: 0px;
        }
        

      }
				
     
      
        
    }

    &__Bottom_shadow {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6.5rem;
      background: linear-gradient(180deg,transparent,rgba(14,14,14,.6),#141414);
    }

    &__button {
      &--vol {
        &:hover{
          .CHPlayer__volume--sound {
            opacity: 1;
          }
        }

        path{
          stroke:white;
        }
      }

      &-wrapper{
        width: 300px;
        position:absolute;
        background: #cccccc;
      }

      &--speed-wrapper {
        width: 25vw;
        position: absolute;
        background: #30303094;
        margin-bottom: 55vh;
        justify-content: right;
        right: 0vw;
        padding: 5px 20px;
      }

      &--speed-selector {
        position: relative;
        color: #ffffff;
            .MuiSlider-root{
              color: #ffffff;
            }
      }
  
      
    }


    

    
   
   
}
