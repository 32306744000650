@import '../../scss/mixin';
@import '../../scss/variables';

.Row {
    display: block;
    padding: 1.5vh 0;

    @include mq('md'){
        padding: 3vh 0;
    }

    &__title {
        margin-bottom: 1rem;
        padding: 0 4%;
        font-size: 16px;
        line-height: 1.25vw;
        text-align: left;
        display: inline-block;

        @include mq('md'){
            font-size: 18px;
        }
        @include mq(1330px){
            font-size: 1.2vw;
        }

        a {
            color: $white;
            text-decoration: none;
        }

        &:hover {
            .Row__showmore {
                opacity: 1;
                max-width: 200px;
                transform: translate(1vw,0);
            }
        }
    }

    &__showmore {
        font-size: 12px;
        line-height: 1vw;
        display: inline-flex;
        align-items: center;
        color: $white;
        white-space: nowrap;
        opacity: 0;
        max-width: 0;
        transform: translateZ(0);
        transition: max-width 450ms, opacity 450ms, transform 750ms;

        @include mq('md'){
            font-size: 14px;
        }
        @include mq(1330px){
            font-size: 0.8vw;
        }
    }

    &__not-loaded {
        padding: 0 4%;
        font-size: 14px;
        color: #ff00009c;
    }

    &__top {
        margin-top:5em;
    }

    .swiper-wrapper {
        @include mq('md') {
            &:hover {
                .swiper-slide {
                    transform: translateX(-15%);
                    opacity: 0.3;

                    &:hover {
                        transform: scale(1.3) !important;
                        z-index: 1;
                        opacity: 1;

                        ~ .swiper-slide {
                            transform: translateX(15%);
                        }
                    }
                }
            }

            &.is-left {
                &:hover {
                    .swiper-slide {
                        &:hover {
                            ~ .swiper-slide {
                                transform: translateX(28%);
                            }
                        }
                    }
                }
            }

            &.is-right {
                &:hover {
                    .swiper-slide {
                        transform: translateX(-30%);
                        opacity: 0.3;

                        &:hover {
                            transform: scale(1.3) !important;
                            z-index: 1;
                            opacity: 1;

                            ~ .swiper-slide {
                                transform: translateX(0%);
                            }
                        }
                    }
                }
            }
        }
    }

    .swiper-slide {
        display: inline-flex;
        transform: scale(1);
        transition: transform 0.3s ease-out, opacity 0.3s ease-out;

        @include mq(330px) {
            &:nth-child(1n) {
                transform-origin: initial;
            }
            &:nth-child(2n+1) {
                transform-origin: left;
            }
            &:nth-child(2n+2) {
                transform-origin: right;
            }
        }
        @include mq(625px) {
            &:nth-child(1n) {
                transform-origin: initial;
            }
            &:nth-child(3n+1) {
                transform-origin: left;
            }
            &:nth-child(3n+3) {
                transform-origin: right;
            }
        }
        @include mq(998px) {
            &:nth-child(1n) {
                transform-origin: initial;
            }
            &:nth-child(4n+1) {
                transform-origin: left;
            }
            &:nth-child(4n+4) {
                transform-origin: right;
            }
        }
        @include mq(1378px) {
            &:nth-child(1n) {
                transform-origin: initial;
            }
            &:nth-child(6n+1) {
                transform-origin: left;
            }
            &:nth-child(6n+6) {
                transform-origin: right;
            }
        }
    }

    .swiper-initialized  {
        overflow: initial;
        width: 100%;
        height: auto;
        padding: 0 4%;

        &:hover {
            .swiper-pagination {
                opacity: 1;
            }
        }
    }

    .swiper-pagination {
        top: -35px !important;
        right: 4.5%;
        left: initial !important;
        bottom: initial !important;
        width: auto !important;
        opacity: 0;
        z-index: 1;
        transform: none;
        transition: opacity 0.2s ease-out;

        &-bullet {
            width: 12px;
            height: 4px;
            display: inline-block;
            margin: 0 !important;
            margin-left: 2px !important;
            border-radius: 0;
            opacity: 1;
            background-color: #4d4d4d;
            transition: all 0.2s ease-in-out;

            &-active {
                background-color: #aaa;
                height: 5px;
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        top: 1.5vh;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        opacity: 0.5;
        width: 4%;
        height: 100%;
        background: rgba($grey, 0.5);
        z-index: 10;
        --swiper-navigation-size: 3vh;
        
        
        &::after {
            transform: scale(0.9);
            transition: opacity 0.2s ease-out, transform 0.2s ease-out;
        }
        &:hover {
            cursor: pointer;
            opacity: 0.7;
            background: rgba($grey, 0.9);
                .swiper-container {
                    .swiper-pagination {
                        opacity: 1;
                    }
                }
        }
        &:hover::after {
            opacity: 1;
            transform: scale(1.3);
        }
    }
    .swiper-button-prev {
        left: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .swiper-button-next {
        right: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .swiper-button-disabled {
        opacity: 0;
    }

    // &__slider {
    //     &--mask {
    //         position: absolute;
    //         top: 0;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         color: $white;
    //         width: 4%;
    //         height: 100%;
    //         background: rgba($grey, 0.5);
    //         z-index: 10;

    //         &.left {
    //             left: 0;
    //             border-top-right-radius: 5px;
    //             border-bottom-right-radius: 5px;
    //         }
    //         &.right {
    //             right: 0;
    //             border-top-left-radius: 5px;
    //             border-bottom-left-radius: 5px;
    //         }

    //         &.left,
    //         &.right {
    //             &:hover {
    //                 .Row__slider--mask-icon {
    //                     opacity: 1;
    //                     transform: scale(1.3);
    //                 }
    //             }
    //         }

    //         &-icon {
    //             opacity: 0;
    //             transform: scale(0.9);
    //             transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    //         }

    //         &.swiper-button-disabled {
    //             opacity: 0.2;
    //         }

    //         &:hover {
    //             cursor: pointer;

    //             ~ .swiper-container {
    //                 .swiper-pagination {
    //                     opacity: 1;
    //                 }
    //             }
    //         }
    //     }
    // }
}