$white: #f2f2f2;
$pure-black: #030303;
$light-black: #181818;
$black: #141414;
$red: #e50914;
$yellow: #cc9400;
$grey: #101011;
$blue-google: #1356c7;

$main-font: 'Inter', sans-serif;
$fakeflix-font: 'Bebas Neue', cursive;