@import "../../scss/mixin";

.Page {
	overflow: hidden;

	&__wrp {
		margin: 5em auto;
		width: 100%;
		padding: 0 10%;
	}

	&__h1 {
		font-size: 1.8em;
		line-height: 1.6;
		text-align: left;

		@include mq("md") {
			font-size: 2em;
		}

		@include mq(1330px) {
			font-size: 2.2em;
		}
	}
    &__h2{
        margin-top: 1em;
    }
    &__h3{
        margin-top: 1em;
    }
    &__h4{
        margin-top: 1em;
    }
    &__p{
        line-height: 1.5;
        font-size: 14px;
    }

    &__list{
        margin-left:1.5em;
    }
    &__list-items{
        font-size: 14px;
        line-height: 1.8;
    }
    &__links{
        color: #f7c600;
        font-style: italic;
        text-decoration: none;
    }
}
