@import '../../scss/mixin';

.Category {
    padding: 2em 4vw;
    margin-top: 7em;

    &__title {
        margin: 0;
        font-size: 16px;
        line-height: 1.25vw;
        text-align: left;

        @include mq('md'){
            font-size: 18px;
        }
        @include mq(1330px){
            font-size: 1.2vw;
        }
    }

    &__subtitle {
        margin: 0;
        font-size: 14px;
        line-height: 1.15vw;
        text-align: center;

        @include mq('md'){
            font-size: 16px;
        }
        @include mq(1330px){
            font-size: 1vw;
        }
    }

    &__wrp {
        margin: 0 auto;
        margin-top: 3vh;
        width: 100%;
    }
}