.react-tel-input {
    margin: 1em 0 1em;
}
.react-tel-input .form-control  {
    width: 100%;
    padding: 1em 1.2em 1em 4.2em;
    border-radius: 5px;
    background-color: #333333;
    font-size: 15px;
    color: #fff;
    outline: none;
    box-shadow: none;
    border: 0;
    border-bottom: 2px solid transparent;
    transition: all 0.2s ease-out;
    height: auto;
}
.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0px;
    background-color: #333333;
    border: 1px solid #333333;
    border-right: 1px solid rgb(255 255 255 / 31%);
    border-radius: 3px 0 0 3px;
}
.react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 50px;
    height: 100%;
    padding: 0 0 0 15px;
    border-radius: 3px 0 0 3px;
}
.react-tel-input .selected-flag .arrow {
    position: relative;
    top: 50%;
    margin-top: -2px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #9b9b9b;
}
.react-tel-input .flag-dropdown.open .selected-flag {
    background: #333333;
    border-radius: 3px 0 0 0;
}
.react-tel-input .country-list {
    outline: none;
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 5px 0 10px -1px;
    box-shadow: 1px 1px 5px rgb(0 0 0);
    background-color: #333333;
    width: 300px;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
}
.react-tel-input .country-list .search {
    position: sticky;
    top: 0;
    background-color: #333;
    padding: 10px 0 6px 10px;
}
.react-tel-input .country-list .search-box {
    border: 1px solid #7c7c7c;
    background: #333333;
    color: #bbbbbb;
    border-radius: 3px;
    font-size: 15px;
    line-height: 15px;
    margin-left: 6px;
    padding: 3px 8px 5px;
    outline: none;
}
.react-tel-input .country-list .country.highlight {
    background-color: #1d1d1d;
}
.react-tel-input .country-list .country:hover {
    background-color: #1d1d1d;
}
.react-tel-input .country-list .country .dial-code {
    color: #acacac;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: #1e1e1e;
}
.react-tel-input .country-list .divider {
    padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid #5c5c5c;
}
.PhoneInput__label {
    margin-top: -0.8em;
    font-size: 13px;
    color: orange;
}
.form-control.Input--error {
    border-bottom: 2px solid orange;
}

.country-list::-webkit-scrollbar {
    width: 7px;
}
.country-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(121, 121, 121, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
.country-list::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: orange;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.country-list::-webkit-scrollbar-thumb:window-inactive {
    background: rgb(178, 116, 0);
}

.react-tel-input .invalid-number-message {
    color: orange;
    left: -2px;
    top: 4.8em;
    font-size: 13px;
    background: transparent;
}
.react-tel-input .form-control.invalid-number,
.react-tel-input .form-control.invalid-number:focus {
    border: 0px;
    border-bottom: 2px solid orange;
    background-color: #333333;
    border-left-color: transparent;
}
.react-tel-input .flag-dropdown.invalid-number {
    border: 0px;
    border-bottom: 2px solid orange;
    border-right: 0px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
