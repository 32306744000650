@import "../../scss/mixin";

.MyList {
	overflow: hidden;

	&__wrp {
		margin: 0 auto;
		margin-top: 3vh;
		width: 100%;
		padding: 0 4%;
	}

	&__title {
		margin: 5em 0 1em;
		font-size: 16px;
		line-height: 1.6;
		text-align: left;

		@include mq("md") {
			font-size: 18px;
		}

		@include mq(1330px) {
			font-size: 1.2vw;
		}
	}
}
