@import '../../scss/mixin';
@import '../../scss/variables';

.Banner {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    height: 40vh;
    background: $black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
        @include mq('xs'){
            height: 50vh;
        }
        @include mq('sm'){
            height: 80vh;
        }
        @include mq('md'){
            height: 95vh;
        }
        @include mq('lg'){
            height: 100vh;
        }

    &__loadsection {
        .loading,
        .errored {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 15vh;
            background: $black;
            font-size: 14px;
            color: #ff000066;
            line-height: 1.6;
            font-weight: 600;
            text-shadow: 2px 2px 4px rgba($color: $black, $alpha: 0.45);
        }
    }

    &__slider {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        background: $black;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;

            &__wrapper {
                background-size: cover;
                width: 100% !important;
                height: 100% !important;
                object-fit: cover;
                object-position: center;
            }

            &__content {
                width: 100%;
                display: flex;
                position: absolute;
                flex-direction: column;
                align-items: center;
                text-align: center;
                padding: 0 4vw 10vh;
                z-index: 1;
                bottom: 0vh;

                @include mq('xs'){
                    bottom: 2vh;
                }
                @include mq('sm'){
                    bottom: -5vh;
                }
                @include mq('md'){
                    bottom: 60px;
                }
                @include mq('lg'){
                    bottom: 90px;
                }

                @include mq(lg) {
                    align-items: flex-start;
                    padding-bottom: unset;
                    text-align: left;
                }

                &--title {
                    font-size: 5vw;
                    line-height: 1;
                    text-shadow: 2px 2px 4px rgba($color: $black, $alpha: 0.45);

                    @include mq('xs'){
                        font-size: 25px;
                    }
                    @include mq('sm'){
                        font-size: 40px;
                    }
                    @include mq('md'){
                        font-size: 50px;
                    }
                    @include mq('lg'){
                        font-size: 60px;
                        max-width: 80%;
                    }
                }
                
                &--description {
                    font-size: 1.7vw;
                    line-height: 1.6;
                    text-shadow: 2px 2px 4px rgba($color: $black, $alpha: 0.45);
                    margin-top: 1rem;
                    max-width: 70vw;

                    @include mq('sm'){
                        font-size: 14px;
                        max-width: 70vw;
                    }
                    @include mq('md'){
                        font-size: 15px;
                        max-width: 60vw;
                    }
                    @include mq('lg'){
                        font-size: 16px;
                        max-width: 60vw;
                        margin-top: 1vw;
                    }
                }
                
            }
    }

    &__buttons {
        display: flex;
        align-items: center;
        margin-top: 1vw;

        &--secondary {
            margin-top: -0.8vw;

            &--btn {
                background-color: rgba(109, 109, 110, 0.7) !important;

                &:hover {
                    background-color: rgba(#6d6d6e, 1) !important;
                }
            }
        }
    }
    
    &__button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 80px;
        background-color: rgba(109, 109, 110, 0.7);
        color: #f2f2f2;
        padding: 10px 12px;
        margin-left: 10px;
        border-radius: 6px;
        border: 0;
        margin-top: 1em;
        font-family: $main-font;
        font-size: 2vw;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;

        @include mq('sm'){
            min-width: 110px;
            font-size: 14px;
            padding: 12px 16px;
        }

        @include mq('md'){
            min-width: 110px;
            font-size: 16px;
            padding: 12px 20px;
        }

        @include mq('lg'){
            min-width: 110px;
            font-size: 18px;
            padding: 12px 24px;
        }
        

        &:hover {
            background-color: rgba(#6d6d6e, 1);
        }

        &:first-child {
            margin-left: 0;
            background-color: $yellow;
            color: $white;

            &:hover {
                background-color: darken($yellow, 5);
            }
        }

        span {
            margin-left: 8px;
        }

        &--large {
            font-size: 2vw;
            padding: 10px 11px;

            @include mq('sm'){
                font-size: 14px;
                padding: 12px 14px;
            }

            @include mq('md'){
                font-size: 16px;
                padding: 13px 15px;
            }

            @include mq('lg'){
                font-size: 18px;
                padding: 15px 16px;
            }
        }

        &--trailer{
            background-color: rgba(109, 109, 110, 0.7) !important;
            &:hover {
                background-color: rgba(#6d6d6e, 1) !important;
            }
        }
    }

    &__buttonIcon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        background-color: transparent;
        color: #f2f2f2;
        padding: 2px 2px;
        margin-left: 10px;
        border-radius: 6px;
        border: 0;
        margin-top: 1em;
        font-family: $main-font;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;

        svg {
            font-size: 2rem;

            @include mq('xs'){
                font-size: 2.3rem;
            }
    
            @include mq('md'){
                font-size: 2.4rem;
            }

            @include mq('lg'){
                font-size: 2.5rem;
            }
        }
        &:hover {
            background-color: rgba(#6d6d6e, 1);;
            svg {
                color: #ffffff
            }
        }
    }

    &__panel {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000000;
        background: linear-gradient(120deg, rgba(0,0,0,0.9) 30%, rgba(255,255,255,0) 100%);
    }

    &__bottom-shadow {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.5rem;
        z-index: 1;
        background: linear-gradient(180deg, transparent, rgba(14, 14, 14, 0.6), $black);

        @include mq('lg'){
            height: 7.5rem;
        }
        @include mq('md'){
            height: 5.5rem;
        }
        @include mq('sm'){
            height: 4.5rem;
        }
        @include mq('xs'){
            height: 3.5rem;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        opacity: 0;
        transition: all 0.5s ease-out;
        color: $white;

        &:hover {
            opacity: 1;
            transition: all 0.5s ease-in;
        }
    }
    .swiper-pagination {
        margin-bottom: 5vh;
    }
    .swiper-pagination-bullet {
        background: $yellow;
    }
}
